/* You can add global styles to this file, and also import other style files */
@import './assets/css/bootstrap.min.css';
@import './assets/css/custom-vendor-style.css';
// @import '../libs//date-range-picker/src/lib//date-range-picker.scss';
@import 'air-datepicker/air-datepicker.css';
.notification-list-group {
  app-notifications {
    position: relative;
    display: inline-flex;
    padding: 0 20px;
    li {
      > a {
        position: relative;
      }
    }
  }
}
.page-content-wrapper {
  padding: 26px 50px;
}

::ng-deep angular-editor-toolbar button i {
  font-family: FontAwesome !important;
}

.mat-column-select {
  width: 50px;
  max-width: 50px;
  word-wrap: break-word;
}
.cursor-pointer {
  cursor: pointer;
}

.modal-backdrop.show {
  opacity: 0.7;
}
.custom-dialog-modal {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .modal-content {
    border: none;
    box-shadow: 0 2px 6px rgba(36, 41, 70, 0.05);
    border-radius: 10px;
  }
  .modal-header {
    background-color: #6259ce;
    color: #fff;
    padding: 15px 30px;
    border: none;
    align-items: center;
  }
  .modal-dialog {
    max-width: 550px;
  }
  .modal-header h4 {
    font-weight: 700;
    text-transform: capitalize;
  }
  .modal-header .close {
    opacity: 1;
    margin: 0;
    padding: 0;
    line-height: 0;
  }
  .modal-body {
    padding: 25px;
  }
  .modal-body p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 28px;
  }
}

.list-filter.radio-select-row {
  display: inline-flex;
  flex-wrap: wrap;
  background-color: #edebfd;
  border: 1px solid #6254d1;
  border-radius: 50px;
  padding: 0px 3px !important;

  span {
    color: #6254d1 !important;
    padding: 12px 23px !important;
    text-transform: none !important;
    font-size: 16px !important;
  }
  .custom-control label {
    input[type='radio']:checked + span {
      color: #6254d1 !important;
      font-weight: 700;
    }
    span:after {
      background-color: #ffff;
      border-radius: 20px;
      border: 1px solid #dbdbdb;
      color: #220bcc !important;
    }
  }
}
.doc-approved {
  border: 1px solid #62a830 !important;
}
.doc-rejected {
  border: 1px solid red !important;
}

.custom-disable-editor {
  min-height: 5rem;
  border: 1px solid grey;
  border-radius: 12px;
  padding: 5px 10px 10px 10px;
  width: 100%;
  color: #8e8e8e;
  background: #f6f6f6;
  border-color: #e5e5e5;
}
.location-map {
  width: 100%;
  min-height: calc(100vh - 30rem);
  max-height: calc(100vh - 165px);
  height: 100%;
}

.modal-open {
  .cdk-overlay-container {
    z-index: 1051 !important;
  }
}

.prefix-batch {
  position: relative;
}
.prefix-batch .prefix {
  color: #8e8e8e;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  top: 0;
  height: 100%;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.prefix-batch .form-control {
  padding-left: 60px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  left: 311px;
  z-index: 1019;
  width: calc(100% - 311px);
  padding: 26px 50px 0px;
  background-color: #f8f7fa;
  @media (max-width: 1600px) {
    padding: 26px 20px 0px;
  }
}

.navigation-bar {
  margin-bottom: 0;
}

.sub-wrapper {
  margin-top: 79px;
  padding-top: 20px;
}

.sticky-page-main-title {
  position: sticky;
  top: 105px;
  padding: 14px 0px;
  background: #f8f7fa;
}

.sticky-vertical-nav {
  position: sticky;
  top: 179px;
}

.cdk-overlay-container {
  z-index: 1051 !important;
}

.head-search-box {
  .clear {
    position: absolute;
    right: 71px;
    top: 13px;
    border-radius: 50%;
    border: 1px solid #b7b7b7;
    overflow: hidden;
    padding: 5px;
    display: flex;

    img {
      width: 12px;
    }
    &:hover {
      background-color: #e5e5e5;
    }
  }
}
.t-badge.refunded {
  color: #08d3e4;
  background: #a7e2e76b;
}
.page-content-section {
  .sticky-page-main-title {
    z-index: 999;
  }
}

.air-datepicker-global-container {
  z-index: 1052;
}

.shiping-package-modal {
  max-height: 100%;
}

.code-container {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}
code {
  &.payloadCode {
    display: block;
    white-space: pre-wrap;
  }
}

.text-blue {
  color: #5495d1;
}

.text-purple {
  color: #6d3597;
}
